(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Contato = function Contato() {
  'use strict';

  _classCallCheck(this, Contato);

  window.addEventListener('load', function () {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation'); // Loop over them and prevent submission

    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('click', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          var serial = $('#formContato').serializeObject();
          var chave = {
            recaptcha: $('#g-recaptcha-response').val()
          };
          $.extend(serial, chave);
          var f_data = {};
          f_data['dados'] = JSON.stringify(serial);
          $.ajax({
            url: '/contato',
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType: 'json',
            data: f_data,
            success: function success(result) {
              console.log(result.resultado.status);

              if (result.resultado.status) {
                $('#formContato')[0].reset();
                $('#alerta').html('<div class="alert alert-success alert-dismissible fade show" role="alert">' + result.resultado.mensagem + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' + '<span aria-hidden="true">&times;</span>' + '</button>' + '</div>');
              } else {
                $('#alerta').html('<div class="alert alert-danger alert-dismissible fade show" role="alert">' + result.resultado.mensagem + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' + '<span aria-hidden="true">&times;</span>' + '</button>' + '</div>');
              }

              $('#linkContato').trigger('click');
            },
            error: function error(xhr, resp, text) {
              console.log(xhr, resp, text);
            }
          });
        }

        form.classList.add('was-validated');
      }, false);
    });
  }, false);
};

var _default = Contato;
exports["default"] = _default;

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Efeito = function Efeito() {
  _classCallCheck(this, Efeito);

  $('a[href*="#"]') // Remove links that don't actually link to anything
  .not('[href="#"]').not('[href="#0"]').click(function (event) {
    // On-page links
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']'); // Does a scroll target exist?

      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function () {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();

          if ($target.is(":focus")) {
            // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable

            $target.focus(); // Set focus again
          }

          ;
        });
      }
    }
  });
};

var _default = Efeito;
exports["default"] = _default;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Recaptcha = function Recaptcha() {
  _classCallCheck(this, Recaptcha);

  grecaptcha.ready(function () {
    // do request for recaptcha token
    // response is promise with passed token
    grecaptcha.execute('6LfrwbMUAAAAAMUGhedmlKCRg6TPTzBGzprp9Il4', {
      action: 'validate_captcha'
    }).then(function (token) {
      // add token value to form
      document.getElementById('g-recaptcha-response').value = token;
    });
  });
};

var _default = Recaptcha;
exports["default"] = _default;

},{}],4:[function(require,module,exports){
"use strict";

var _efeito = _interopRequireDefault(require("./efeito.js"));

var _recaptcha = _interopRequireDefault(require("./recaptcha.js"));

var _contato = _interopRequireDefault(require("./contato.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var efeito = new _efeito["default"]();
var recaptcha = new _recaptcha["default"]();
var contato = new _contato["default"]();

},{"./contato.js":1,"./efeito.js":2,"./recaptcha.js":3}]},{},[4]);
